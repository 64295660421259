import React, {
  useReducer,
  createContext,
} from 'react'
import moment from 'moment';
import PropTypes from 'prop-types';
import Axios from 'axios';
import {
  GET_PO,
  DELETE_PO,
  UPDATE_PO,
  GET_POITEMS,
  UPDATE_POITEM,
  EDIT_ATTACHED,
  DELETE_ATTACHED,
  reducer,
} from './PurchaseOrderReducer';
import { headers } from '../../config/token';
import { displayErrors, displayNotification } from '../../config/display';

export const PoContext = createContext({});
const PurchaseOrderContext = ({ children }) => {
  const API = process.env.REACT_APP_API_URL || 'http://api.exelpack.site/api/';
  const defaultValue = ({
    poList: [],
    poInvoiceList: [],
    supplierOption: [],
    poItems: [],

  });
  const [poValue, dispatch] = useReducer(reducer, defaultValue);

  const getFilterParams = (filter) => {
    let params = '';
    Object.keys(filter).forEach((key) => {
      params += filter[key] ? `&${key}=${filter[key]}` : ''
    })
    return params
  }

  const getPo = (setLoading, filter = {}, recordLimit = 1000) => {
    setLoading(true);
    const parameters = getFilterParams(filter);
    Axios.get(`${API}psms/po?recordCount=${recordLimit}${parameters}`, headers())
      .then((res) => {
        const { poList, supplierList } = res.data;
        dispatch({
          type: GET_PO,
          payload: {
            poList,
            supplierList,
          },
        })
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        displayErrors(err);
      })
  }

  const printPo = (setLoading, id) => {
    setLoading(true);
    Axios({
      url: `${API}psms/po/print/${id}`,
      method: 'get',
      responseType: 'blob',
      ...headers(),
    })
      .then((res) => {
        displayNotification('success', 'File successfully generated');
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [res.data],
          { type: 'application/pdf' },
        );
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        window.open(fileURL);
        setLoading(false);
      })
  }

  const cancelPo = (id, setLoading) => {
    setLoading(true);
    Axios.delete(`${API}psms/po/${id}`, headers())
      .then((res) => {
        const { message } = res.data;
        dispatch({
          type: DELETE_PO,
          payload: {
            id,
          },
        })
        displayNotification('success', message);
        setLoading(false);
      })
      .catch((err) => {
        displayErrors(err);
        setLoading(false);
      })
  }

  const updatePo = (values, setLoading) => {
    setLoading(true);
    Axios.put(`${API}psms/po/${values.id}`, values, headers())
      .then((res) => {
        const { message, newPo } = res.data;
        dispatch({
          type: UPDATE_PO,
          payload: {
            newPo,
          },
        })
        displayNotification('success', message);
        setLoading(false);
      })
      .catch((err) => {
        displayErrors(err);
        setLoading(false);
      })
  }

  const getPoInfo = (id) => Axios.get(`${API}psms/po/${id}`, headers())
    .then((res) => {
      const { poItems } = res.data;
      return poItems;
    })
    .catch((err) => {
      displayErrors(err);
      throw new Error('Something wrong with the server')
    })

  const getPoItems = (setLoading, filter = {}, recordLimit = 1000) => {
    setLoading(true);
    const parameters = getFilterParams(filter);
    Axios.get(`${API}psms/poitem?recordCount=${recordLimit}${parameters}`, headers())
      .then((res) => {
        const { poItems, supplierList } = res.data;
        dispatch({
          type: GET_POITEMS,
          payload: {
            poItems,
            supplierList,
          },
        });
        setLoading(false);
      })
      .catch((err) => {
        displayErrors(err);
        setLoading(false);
      })
  }

  const getPoItemDeliveries = (id) => Axios.get(`${API}psms/po/invoice/${id}`, headers())
    .then((res) => {
      const { supplierDeliveries } = res.data;
      return supplierDeliveries;
    })
    .catch((err) => {
      displayErrors(err);
      throw new Error('Something wrong with the server');
    })

  const addPoItemDelivery = (values) => Axios.post(`${API}psms/po/invoice`, values, headers())
    .then((res) => {
      const { newDelivery, newItem, message } = res.data;
      dispatch({
        type: UPDATE_POITEM,
        payload: {
          newItem,
        },
      })
      displayNotification('success', message);
      return newDelivery;
    })
    .catch((err) => {
      displayErrors(err);
      throw new Error('Something wrong with the server');
    })

  const editPoItemDelivery = (values) => Axios.put(`${API}psms/po/invoice/${values.id}`, values, headers())
    .then((res) => {
      const { newDelivery, newItem, message } = res.data;
      dispatch({
        type: UPDATE_POITEM,
        payload: {
          newItem,
        },
      })
      displayNotification('success', message);
      return newDelivery;
    })
    .catch((err) => {
      displayErrors(err);
      throw new Error('Something wrong with the server');
    })

  const deletePoItemDelivery = (id) => Axios.delete(`${API}psms/po/invoice/${id}`, headers())
    .then((res) => {
      const { newItem, message } = res.data;
      dispatch({
        type: UPDATE_POITEM,
        payload: {
          newItem,
        },
      })
      displayNotification('success', message);
    })
    .catch((err) => {
      displayErrors(err);
      throw new Error('Something wrong with the server');
    })

  const exportPoSupplierCsv = (setLoading, filter = {}, recordCount = 500) => {
    setLoading(true);
    const dateNow = moment().format('Y_M_D');
    const parameters = getFilterParams(filter);
    Axios({
      url: `${API}psms/po/exports/dl?recordCount=${recordCount}${parameters}`,
      method: 'get',
      responseType: 'blob',
      ...headers(),
    })
      .then((res) => {
        displayNotification('success', 'File successfully generated');
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `PurcaseOrdersSupplier_report_as_of_${dateNow}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
        setLoading(false);
      })
      .catch((err) => {
        displayErrors(err);
        setLoading(false);
      })
  }

  const exportPoItemSupplierCsv = (setLoading, filter = {}, recordCount = 500) => {
    setLoading(true);
    const dateNow = moment().format('Y_M_D');
    const parameters = getFilterParams(filter);
    Axios({
      url: `${API}psms/poitem/exports/dl?recordCount=${recordCount}${parameters}`,
      method: 'get',
      responseType: 'blob',
      ...headers(),
    })
      .then((res) => {
        displayNotification('success', 'File successfully generated');
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `PurcaseOrdersItemsSupplier_report_as_of_${dateNow}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
        setLoading(false);
      })
      .catch((err) => {
        displayErrors(err);
        setLoading(false);
      })
  }

  const getPurchasesReport = (date = []) => (
    Axios.post(`${API}psms/purchasesreport`, { date }, headers())
      .then((res) => res)
      .catch((err) => {
        displayErrors(err);
        throw new Error(err);
      })
  )

  const exportPurchasesReportCsv = (setLoading, date) => {
    setLoading(true);
    Axios({
      url: `${API}psms/purchasesreport/exports/dl`,
      method: 'post',
      responseType: 'blob',
      data: { date },
      ...headers(),
    })
      .then((res) => {
        displayNotification('success', 'File successfully generated');
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `Purcases_report_from_${date[0]}_to_${date[1]}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
        setLoading(false);
      })
      .catch((err) => {
        displayErrors(err);
        setLoading(false);
      })
  }

  const createPoApprovalRequest = (values) => Axios.post(`${API}psms/po/approval`, values, headers())
    .then((res) => {
      const { newApprovalRequest, newPo, message } = res.data;
      dispatch({
        type: UPDATE_PO,
        payload: { newPo },
      });
      displayNotification('success', message);
      return { newApprovalRequest };
    })
    .catch((err) => {
      displayErrors(err);
      throw new Error('Error');
    })

  const deletePoApprovalRequest = (id) => Axios.delete(`${API}psms/po/approval/${id}`, headers())
    .then((res) => {
      const { message, newPo } = res.data;
      dispatch({
        type: UPDATE_PO,
        payload: { newPo },
      });
      displayNotification('success', message);
    })
    .catch((err) => {
      displayErrors(err);
      throw new Error('Error');
    })


  const downloadAttachment = (id, type, setLoading) => {
    setLoading(true);
    Axios({
      url: API + `psms/po/attachment/${id}/${type}`,
      method: 'get',
      responseType: 'blob',
      ...headers()
    })
      .then(res => {
        displayNotification('success', 'File successfully generated');
        //Create a Blob from the PDF Stream
        const file = new Blob(
          [res.data],
          { type: 'application/pdf' });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
        setLoading(false);

      })
      .catch(err => {
        displayErrors(err);
        setLoading(false);
      })

  }
    

    const addAttachment = async (values, setLoading) => {


      setLoading(true);
      const data = new FormData();
      for (let key in values) {
        data.append([key], values[key]);
      }
  
      try {
        const res = await Axios.post(API + `psms/po/attachment`, data, headers());
        const { message, newItem } = res.data;
        dispatch({
          type: EDIT_ATTACHED,
          payload: {
            newItem,
            id: newItem.id,
          },
        })
        displayNotification('success', message);
        setLoading(false);
      }
      catch(err) {
        displayErrors(err);
        throw new Error('Error');
      }
  
  
    }

    // const deleteAttachment = async (id, type, setLoading) => {

    //   setLoading(true);
    //   try {
    //     const res = await Axios.delete(API + `psms/po/attachment/${id}?type=${type}`, headers());
    //     const { message, newItem } = res.data;
    //     dispatch({
    //       type: DELETE_ATTACHED,
    //       payload: {
    //         newItem,
    //         id: newItem.id
    //       }
    //     });
    //     displayNotification('success', message);
    //     setLoading(false);
    //   }
    //   catch (err) {
    //     displayErrors(err);
    //     setLoading(false);
    //     throw new Error();
    //   }
  
    // }


    const deleteAttachment = async (id, type, setLoading) => {

      setLoading(true);
      try {
        const res = await Axios.delete(API + `psms/po/attachment/${id}?type=${type}`, headers());
        const { message, newItem } = res.data;
        dispatch({
          type: DELETE_ATTACHED,
          payload: {
            newItem,
            id: newItem.id
          }
        });
        displayNotification('success', message);
        setLoading(false);
      }
      catch (err) {
        displayErrors(err);
        setLoading(false);
        throw new Error();
      }
  
    }

    
  

    

  return (
    <PoContext.Provider
      value={{
        poValue,
        getPo,
        printPo,
        cancelPo,
        updatePo,
        getPoInfo,
        getPoItems,
        getPoItemDeliveries,
        addPoItemDelivery,
        editPoItemDelivery,
        deletePoItemDelivery,
        exportPoSupplierCsv,
        exportPoItemSupplierCsv,
        getPurchasesReport,
        exportPurchasesReportCsv,
        createPoApprovalRequest,
        deletePoApprovalRequest,
        addAttachment,
        deleteAttachment,
        downloadAttachment,
      }}
    >
      {children}
    </PoContext.Provider>
  )
}

PurchaseOrderContext.propTypes = {
  children: PropTypes.element.isRequired,
}

export default PurchaseOrderContext
