import React, {
  useState,
  useEffect,
  useContext,
  Suspense,
} from 'react';
import {
  Button,
  notification,
} from 'antd';
import Layout from '../layout/Layout';
import { PrContext } from '../context/PurchaseRequestContext/PurchaseRequestContext';
import { Utils } from '../context/UtilsContext';

import searchArray from '../components/searchArray';
import PurchaseRequestPriceTable from '../components/PurchaseRequestWithPrice/PurchaseRequestPriceTable';
import Filter from '../components/Filter/Filter';
import PrFilter from '../components/Filter/PrFilter';

const PurchaseRequestModal = React.lazy(() => import('../components/PurchaseRequest/PurchaseRequestModal'));
const PurchaseRequestPriceModal = React.lazy(() => import('../components/PurchaseRequestWithPrice/PurchaseRequestPriceModal'));
const PurchaseOrderModal = React.lazy(() => import('../components/PurchaseOrder/PurchaseOrderModal'));

const PrView = () => {
  const {
    prValue,
    getPrListPrice,
    getPrDetails,
    deletePrPrice,
    getPrDetailsForPO,
    addPurchaseOrderToPR,
    printPr,
  } = useContext(PrContext);
  const { setLoading } = useContext(Utils);

  useEffect(() => getPrListPrice(setLoading, 1000), []);
  const [searchRecord, setSearchRecord] = useState({
    search: '',
    throttle: 500,
  });

  // filter
  const defaultFilter = {
    supplier: null,
    currency: null,
    month: null,
    year: null,
  }
  const [filter, setFilter] = useState(defaultFilter);
  const onFilter = () => getPrListPrice(setLoading, filter, searchRecord.throttle);
  const onResetFilter = () => {
    setFilter(defaultFilter);
    getPrListPrice(setLoading, defaultFilter, searchRecord.throttle);
  };
  // end filter
  const [modalData, setModalData] = useState({ data: {}, visible: false, type: '' });
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const { prPriceList, supplierOption } = prValue;

  // modal
  const openModal = (data, type) => setModalData({ visible: true, data, type });

  const closeModal = () => {
    setModalData({ visible: false, data: {}, type: '' });
  }

  const checkifSameSupplier = () => selectedRows.every((row) => (
    row.supplier === selectedRows[0].supplier
    && row.currency === selectedRows[0].currency
    && row.poNum === selectedRows[0].poNum
  ))

  const onCancelSelectingPO = () => {
    setIsSelecting(false);
    setSelectedRows([])
  }
  // search
  const filteredPrList = searchArray(searchRecord.search, prPriceList, ['prNum', 'joNum', 'poNum']);
  const refreshContent = (val = filter.throttle) => getPrListPrice(setLoading, filter, val);
  // const exportRecordToCsv = () => exportDeliveredItems(setLoading, filter,searchRecord.throttle);
  // end search
  return (
    <>
      <br />
      <h2>PENDING PURCHASE REQUEST LIST</h2>
      <Filter
        filter={searchRecord}
        setFilter={setSearchRecord}
        refreshContent={refreshContent}
      />

      <PrFilter
        filter={filter}
        setFilter={setFilter}
        onFilter={onFilter}
        onResetFilter={onResetFilter}
        supplierList={supplierOption}
      />
      {!isSelecting
        ? (
          <Button
            onClick={() => setIsSelecting(true)}
            style={{ marginRight: 5, marginBottom: 5 }}
            icon="select"
            type="ghost"
            size="default"
            className="bl-cl"
          >
            Add purchase order
          </Button>
        )
        : (
          <>
            <Button
              onClick={() => {
                if (checkifSameSupplier()) {
                  openModal(selectedRows, 'po');
                } else {
                  notification.warning({
                    description: "Purchase request w/ same customer's purchase order, supplier and currency is allowed to be merge.",
                    message: 'Warning',
                  });
                }
              }}
              className="bl-cl"
              style={{ marginRight: 5, marginBottom: 5 }}
              icon="form"
              size="default"
              disabled={selectedRows.length < 1}
            >
              Create purchase order
            </Button>
            <Button
              onClick={onCancelSelectingPO}
              className="red-cl"
              style={{ marginRight: 5, marginBottom: 5 }}
              icon="close-circle"
              size="default"
            >
              Cancel
            </Button>
          </>
        )}

      <PurchaseRequestPriceTable
        prList={filteredPrList}
        openModal={openModal}
        deletePrPrice={deletePrPrice}
        setLoading={setLoading}
        isSelecting={isSelecting}
        setSelectedRows={setSelectedRows}
        printPr={printPr}
        supplierOption={supplierOption}
      />

      {modalData.visible && modalData.type === 'pr' && (
        <Suspense fallback={<div>Loading Modal...</div>}>
          <PurchaseRequestModal
            visible={modalData.visible}
            data={modalData.data}
            getPrDetails={getPrDetails}
            closeModal={closeModal}
          />
        </Suspense>
      )}

      {modalData.visible && modalData.type === 'approval' && (
        <Suspense fallback={<div>Loading Modal...</div>}>
          <PurchaseRequestPriceModal
            visible={modalData.visible}
            data={modalData.data}
            closeModal={closeModal}
          />
        </Suspense>
      )}

      {modalData.visible && modalData.type === 'po' && (
        <Suspense fallback={<div>Loading Modal...</div>}>
          <PurchaseOrderModal
            visible={modalData.visible}
            data={modalData.data}
            closeModal={closeModal}
            getPrDetailsForPO={getPrDetailsForPO}
            addPurchaseOrderToPR={addPurchaseOrderToPR}
            setLoading={setLoading}
            onCancelSelectingPO={onCancelSelectingPO}
          />
        </Suspense>
      )}
    </>
  );
}

export default Layout(PrView);
